import isNumeric from 'fast-isnumeric';
import { LexemeType } from 'core/syntax-tree/lexicon';
const FIELD_REGEX = /^{(([^{}\\]|\\.)+)}/;
const STRING_REGEX = /^(('([^'\\]|\\.)*')|("([^"\\]|\\.)*")|(`([^`\\]|\\.)*`))/;
const VALUE_REGEX = /^(([^\s'"`{}()\\]|\\.)+)(?:[\s)]|$)/;
const PERMISSIVE_VALUE_REGEX = /^(([^'"`{}()\\]|\\.)+)$/;
const getField = (value) => value.slice(1, value.length - 1).replace(/\\(.)/g, '$1');
export const fieldExpression = {
    present: (tree) => getField(tree.value),
    resolve: (target, tree) => {
        if (FIELD_REGEX.test(tree.value)) {
            return target[getField(tree.value)];
        }
        else {
            throw new Error();
        }
    },
    regexp: FIELD_REGEX,
    subType: 'field',
    type: LexemeType.Expression
};
const getString = (value) => value.slice(1, value.length - 1).replace(/\\(.)/g, '$1');
export const stringExpression = {
    present: (tree) => getString(tree.value),
    resolve: (_target, tree) => {
        if (STRING_REGEX.test(tree.value)) {
            return getString(tree.value);
        }
        else {
            throw new Error();
        }
    },
    regexp: STRING_REGEX,
    subType: 'value',
    type: LexemeType.Expression
};
const getValueFactory = (regex) => (value) => {
    value = value.match(regex)[1];
    return isNumeric(value) ? +value : value.replace(/\\(.)/g, '$1');
};
const valueExpressionFactory = (regex, transform) => {
    const getValue = getValueFactory(regex);
    return {
        present: (tree) => getValue(tree.value),
        resolve: (_target, tree) => {
            if (regex.test(tree.value)) {
                return getValue(tree.value);
            }
            else {
                throw new Error();
            }
        },
        regexp: regex,
        regexpMatch: 1,
        subType: 'value',
        transform,
        type: LexemeType.Expression
    };
};
export const valueExpression = valueExpressionFactory(VALUE_REGEX);
export const permissiveValueExpression = valueExpressionFactory(PERMISSIVE_VALUE_REGEX, (v) => (typeof v === 'string' && v.indexOf(' ') !== -1 ? `"${v}"` : v));
