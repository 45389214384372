import * as R from 'ramda';
import { LexemeType } from 'core/syntax-tree/lexicon';
const nestingReducer = R.reduce((nesting, l) => nesting + (l.lexeme.nesting || 0));
export const isTerminal = (lexemes, _) => nestingReducer(0, lexemes) === 0;
export const isTerminalExpression = (lexemes, previous) => isTerminal(lexemes, previous) &&
    !!previous &&
    R.includes(previous.lexeme.type, [LexemeType.RelationalOperator]);
export const ifBlockClose = (lexemes, previous) => !!previous &&
    R.includes(previous.lexeme.type, [
        LexemeType.BlockClose,
        LexemeType.BlockOpen,
        LexemeType.Expression,
        LexemeType.UnaryOperator
    ]) &&
    nestingReducer(0, lexemes) > 0;
export const ifBlockOpen = (_, previous) => !previous ||
    R.includes(previous.lexeme.type, [
        LexemeType.BlockOpen,
        LexemeType.LogicalOperator,
        LexemeType.UnaryOperator
    ]);
export const ifExpression = (_, previous) => {
    return (!previous ||
        R.includes(previous.lexeme.type, [
            LexemeType.BlockOpen,
            LexemeType.LogicalOperator,
            LexemeType.RelationalOperator
        ]));
};
export const ifLeading = (_lexs, previous) => !previous;
export const ifLogicalOperator = (_, previous) => !!previous &&
    R.includes(previous.lexeme.type, [
        LexemeType.BlockClose,
        LexemeType.Expression,
        LexemeType.UnaryOperator
    ]);
export const ifRelationalOperator = (_, previous) => !!previous && R.includes(previous.lexeme.type, [LexemeType.Expression]);
export const ifUnaryOperator = ifRelationalOperator;
