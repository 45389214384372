import * as R from 'ramda';
import React from 'react';
import memoizerCache from 'core/cache/memoizer';
import derivedCellEventHandlerProps, { Handler } from 'dash-table/derived/cell/eventHandlerProps';
class Wrappers {
    handlers;
    constructor(propsFn, handlers = derivedCellEventHandlerProps(propsFn)) {
        this.handlers = handlers;
    }
    get = (columns, labelsAndIndices, mergeHeaders) => labelsAndIndices.map(([labels, indices], rowIndex) => indices.map((columnIndex, i) => {
        const column = columns[columnIndex];
        let colSpan;
        if (!mergeHeaders) {
            colSpan = 1;
        }
        else {
            if (columnIndex === R.last(indices)) {
                colSpan = labels.length - columnIndex;
            }
            else {
                colSpan = indices[i + 1] - columnIndex;
            }
        }
        return this.wrapper.get(rowIndex, columnIndex)(columnIndex, column.id, colSpan, columnIndex === columns.length - 1 ||
            columnIndex === R.last(indices), this.handlers(Handler.EnterHeader, rowIndex, columnIndex), this.handlers(Handler.Leave, rowIndex, columnIndex), this.handlers(Handler.MoveHeader, rowIndex, columnIndex));
    }));
    /**
     * Returns the wrapper for a header cell.
     */
    wrapper = memoizerCache()((columnIndex, columnId, colSpan, lastIndex, onEnter, onLeave, onMove) => (React.createElement("th", { key: `header-cell-${columnIndex}`, "data-dash-column": columnId, colSpan: colSpan, className: 'dash-header ' +
            `column-${columnIndex} ` +
            (lastIndex ? 'cell--right-last ' : ''), onMouseEnter: onEnter, onMouseLeave: onLeave, onMouseMove: onMove })));
}
export default (propsFn) => new Wrappers(propsFn);
