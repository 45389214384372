import React, { Component } from 'react';
import * as R from 'ramda';
import { memoizeOne } from 'core/memoizer';
import ControlledTable from 'dash-table/components/ControlledTable';
import 'react-select/dist/react-select.css';
import './Table.less';
import './style';
import './Dropdown.css';
import derivedFilterMap from 'dash-table/derived/filter/map';
import controlledPropsHelper from './controlledPropsHelper';
import derivedPropsHelper from './derivedPropsHelper';
import DOM from 'core/browser/DOM';
import shouldComponentUpdate from './shouldComponentUpdate';
export default class Table extends Component {
    constructor(props) {
        super(props);
        this.state = {
            workFilter: {
                value: props.filter_query,
                map: this.filterMap(new Map(), props.filter_action.operator, props.filter_query, props.visibleColumns)
            },
            rawFilterQuery: '',
            scrollbarWidth: 0
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState(state => {
            const { applyFocus: currentApplyFocus, workFilter: { map: currentMap, value } } = state;
            const nextState = {};
            // state for filter
            if (nextProps.filter_query !== this.props.filter_query) {
                if (value !== nextProps.filter_query) {
                    const map = this.filterMap(currentMap, nextProps.filter_action.operator, nextProps.filter_query, nextProps.visibleColumns);
                    if (map !== currentMap) {
                        nextState.workFilter = { map, value };
                    }
                }
            }
            // state for applying focus
            if (nextProps.active_cell !== this.props.active_cell) {
                nextState.applyFocus = true;
            }
            else if (nextProps.loading_state !== this.props.loading_state) {
                const activeElement = document.activeElement;
                const tdElement = DOM.getFirstParentOfType(activeElement, 'td');
                const tableElement = DOM.getParentById(tdElement, this.props.id);
                nextState.applyFocus = !!tableElement;
            }
            if (nextState.applyFocus === currentApplyFocus) {
                delete nextState.applyFocus;
            }
            return R.keysIn(nextState).length ? nextState : null;
        });
    }
    shouldComponentUpdate(nextProps, nextState) {
        const props = this.props;
        const state = this.state;
        return shouldComponentUpdate(props, nextProps, state, nextState);
    }
    render() {
        const controlled = this.controlledPropsHelper(this.controlledSetProps, this.controlledSetState, this.props, this.state);
        this.updateDerivedProps(controlled, this.controlledSetProps);
        return React.createElement(ControlledTable, { ...controlled });
    }
    get controlledSetProps() {
        return this.__setProps(this.props.setProps);
    }
    get controlledSetState() {
        return this.__setState();
    }
    __setProps = memoizeOne((setProps) => {
        return setProps
            ? (newProps) => {
                if (R.has('data', newProps)) {
                    const { data } = this.props;
                    newProps.data_timestamp = Date.now();
                    newProps.data_previous = data;
                }
                setProps(newProps);
            }
            : (newProps) => {
                this.setState(newProps);
            };
    });
    __setState = memoizeOne(() => (state) => this.setState(state));
    filterMap = derivedFilterMap();
    controlledPropsHelper = controlledPropsHelper();
    updateDerivedProps = derivedPropsHelper();
}
