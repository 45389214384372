import React, { PureComponent } from 'react';
import { KEY_CODES } from 'dash-table/utils/unicode';
export default class IsolatedInput extends PureComponent {
    static defaultProps = {
        stopPropagation: false,
        updateOnEnter: true,
        updateOnBlur: true,
        updateOnSubmit: true
    };
    get propsWithDefaults() {
        return this.props;
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { value } = this.props;
        const { value: nextValue } = nextProps;
        if (value !== nextValue) {
            this.setState({
                value: nextValue
            });
        }
    }
    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        };
    }
    handleKeyDown = (e) => {
        const { stopPropagation, updateOnEnter } = this.propsWithDefaults;
        if (stopPropagation) {
            e.stopPropagation();
        }
        if (updateOnEnter && e.keyCode === KEY_CODES.ENTER) {
            this.submit();
        }
    };
    handleChange = (ev) => {
        this.setState({
            value: ev.target.value
        });
    };
    submit = () => this.state.value !== this.props.value &&
        this.props.submit(this.state.value);
    render() {
        const { onCopy, onPaste, placeholder, updateOnBlur, updateOnSubmit } = this.propsWithDefaults;
        const props = {
            onBlur: updateOnBlur ? this.submit : undefined,
            onKeyDown: this.handleKeyDown,
            onSubmit: updateOnSubmit ? this.submit : undefined
        };
        return (React.createElement("input", { ref: 'input', type: 'text', value: this.state.value || '', onChange: this.handleChange, onCopy: onCopy, onPaste: onPaste, placeholder: placeholder, ...props }));
    }
}
