import { getCache } from '.';
export default () => (fn) => {
    const cache = new Map();
    function get(...key) {
        const lastKey = key.slice(-1)[0];
        const nestedCache = getCache(cache, ...key);
        return nestedCache.has(lastKey)
            ? nestedCache.get(lastKey)
            : nestedCache.set(lastKey, fn(...key)).get(lastKey);
    }
    return { get };
};
