import { isNully, reconcileNull } from './null';
export function coerce(value, options) {
    return isNully(value)
        ? reconcileNull(value, options)
        : typeof value === 'string'
            ? { success: true, value }
            : { success: true, value: JSON.stringify(value) };
}
export function validate(value, options) {
    return typeof value === 'string'
        ? { success: true, value }
        : reconcileNull(value, options);
}
