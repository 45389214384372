import * as R from 'ramda';
export default (columns, labels, mergeHeaders) => {
    return R.map(rowLabels => {
        if (!mergeHeaders) {
            return R.range(0, columns.length);
        }
        else {
            const columnIndices = [0];
            let compareIndex = 0;
            rowLabels.forEach((label, i) => {
                if (label === rowLabels[compareIndex]) {
                    return;
                }
                columnIndices.push(i);
                compareIndex = i;
            });
            return columnIndices;
        }
    }, labels);
};
