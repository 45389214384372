import React from 'react';
import { ExportHeaders, ExportFormat, ExportColumns } from 'dash-table/components/Table/props';
import { createWorkbook, createHeadings, exportWorkbook } from './utils';
import getHeaderRows from 'dash-table/derived/header/headerRows';
export default React.memo((props) => {
    const { columns, export_columns, export_format, virtual_data, export_headers, visibleColumns, merge_duplicate_headers } = props;
    const isFormatSupported = export_format === ExportFormat.Csv ||
        export_format === ExportFormat.Xlsx;
    const exportedColumns = export_columns === ExportColumns.Visible ? visibleColumns : columns;
    const handleExport = async () => {
        const columnID = exportedColumns.map(column => column.id);
        const columnHeaders = exportedColumns.map(column => column.name);
        const maxLength = getHeaderRows(columns);
        const heading = export_headers !== ExportHeaders.None
            ? createHeadings(columnHeaders, maxLength)
            : [];
        const wb = await createWorkbook(heading, virtual_data.data, columnID, export_headers, merge_duplicate_headers);
        await exportWorkbook(wb, export_format);
    };
    return (React.createElement("div", null, !isFormatSupported ? null : (React.createElement("button", { className: 'export', onClick: handleExport }, "Export"))));
});
