import { Remarkable } from 'remarkable';
import objPropsToCamel from 'core/objPropsToCamel';
import LazyLoader from 'dash-table/LazyLoader';
export default class Markdown {
    options;
    md;
    constructor(options) {
        this.options = options;
        this.md = new Remarkable({
            highlight: (str, lang) => {
                if (Markdown.hljs) {
                    if (lang && Markdown.hljs.getLanguage(lang)) {
                        try {
                            return Markdown.hljs.highlight(lang, str).value;
                        }
                        catch (err) { }
                    }
                    try {
                        return Markdown.hljs.highlightAuto(str).value;
                    }
                    catch (err) { }
                }
                else {
                    Markdown.loadhljs();
                }
                return '';
            },
            ...objPropsToCamel(this.options)
        });
    }
    render = (value) => this.md.render(value);
    static get isReady() {
        return Markdown._isReady;
    }
    static hljs;
    static hljsResolve;
    static _isReady = new Promise(resolve => {
        Markdown.hljsResolve = resolve;
    });
    static async loadhljs() {
        Markdown.hljs = await LazyLoader.hljs;
        Markdown.hljsResolve();
        Markdown._isReady = true;
    }
}
