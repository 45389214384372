import * as R from 'ramda';
import React from 'react';
import { memoizeOneFactory } from 'core/memoizer';
function renderFragment(cells, offset = 0) {
    return cells ? (React.createElement("table", { className: 'cell-table', tabIndex: -1 },
        React.createElement("tbody", null, cells.map((row, idx) => (React.createElement("tr", { key: `row-${idx + offset}` }, row)))))) : null;
}
const getHiddenCell = (cell) => React.cloneElement(cell, {
    ...cell.props,
    className: cell.props.className
        ? `${cell.props.className} phantom-cell`
        : 'phantom-cell'
}, cell.type === 'th' || cell.type === 'td' ? null : cell.props.children);
const getFixedColSpan = (cell, maxColSpan) => React.cloneElement(cell, {
    ...cell.props,
    colSpan: R.isNil(cell.props.colSpan)
        ? cell.props.colSpan
        : Math.min(cell.props.colSpan, maxColSpan)
});
const getLastOfType = (cell) => React.cloneElement(cell, {
    ...cell.props,
    className: cell.props.className
        ? `${cell.props.className} last-of-type`
        : 'last-of-type'
});
const isEmpty = (cells) => !cells || cells.length === 0 || cells[0].length === 0;
export default memoizeOneFactory((fixedColumns, fixedRows, cells, offset) => {
    const getPivot = (row) => R.reduceWhile(acc => acc.count < fixedColumns, (acc, cell) => {
        acc.cells++;
        acc.count += cell.props.colSpan || 1;
        return acc;
    }, { cells: 0, count: 0 }, row).cells;
    // slice out fixed columns
    let fixedColumnCells = fixedColumns
        ? R.map(row => {
            const pivot = getPivot(row);
            const res = row
                .slice(0, pivot)
                .map((c, i) => getFixedColSpan(c, fixedColumns - i - 1))
                .concat(row.slice(pivot).map(getHiddenCell));
            res[pivot - 1] = getLastOfType(res[pivot - 1]);
            return res;
        }, cells)
        : null;
    cells = R.isNil(fixedColumnCells)
        ? cells
        : R.map(row => {
            const pivot = getPivot(row);
            return row
                .slice(0, pivot)
                .map(getHiddenCell)
                .concat(row.slice(pivot));
        }, cells);
    // slice out fixed rows
    const fixedRowCells = fixedRows ? cells.slice(0, fixedRows) : null;
    cells = cells.slice(fixedRows);
    const fixedRowAndColumnCells = fixedRows && fixedColumnCells
        ? fixedColumnCells.slice(0, fixedRows)
        : null;
    fixedColumnCells =
        fixedColumnCells && fixedColumnCells.slice(fixedRows);
    return {
        grid: [
            [
                renderFragment(fixedRowAndColumnCells),
                renderFragment(fixedRowCells)
            ],
            [
                renderFragment(fixedColumnCells),
                renderFragment(cells, offset)
            ]
        ],
        empty: [
            [isEmpty(fixedRowAndColumnCells), isEmpty(fixedRowCells)],
            [isEmpty(fixedColumnCells), isEmpty(cells)]
        ]
    };
});
