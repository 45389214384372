import * as R from 'ramda';
export default (virtualization, uiCell, uiHeaders, uiViewport, viewport, rowPadding, scrollbarWidth) => {
    const base = [{}, { fragment: { marginRight: scrollbarWidth } }];
    if (!virtualization || !uiCell || !uiViewport) {
        return [base, [{}, {}]];
    }
    const fullHeight = uiCell.height * viewport.data.length;
    const virtualizedHeight = (Math.floor(uiViewport.scrollTop / uiCell.height) - rowPadding.before) *
        uiCell.height;
    const headersHeight = R.sum(R.map(h => h.height, uiHeaders || []));
    const marginTop = virtualization && uiViewport && uiCell
        ? Math.max(virtualizedHeight - headersHeight, 0)
        : 0;
    const height = Math.max(fullHeight - marginTop, 0);
    return [base, [{ cell: { marginTop } }, { fragment: { height, marginTop } }]];
};
