import { memoizeOneFactory } from 'core/memoizer';
import { lastPage } from 'dash-table/derived/paginator';
import { TableAction } from 'dash-table/components/Table/props';
function getNoPagination(data, indices) {
    return { data, indices };
}
function getFrontEndPagination(page_current, page_size, data, indices) {
    const currentPage = Math.min(page_current, lastPage(data, page_size));
    const firstIndex = page_size * currentPage;
    const lastIndex = Math.min(firstIndex + page_size, data.length);
    return {
        data: data.slice(firstIndex, lastIndex),
        indices: indices.slice(firstIndex, lastIndex)
    };
}
function getBackEndPagination(data, indices) {
    return { data, indices };
}
const getter = (page_action, page_current, page_size, data, indices) => {
    switch (page_action) {
        case TableAction.None:
            return getNoPagination(data, indices);
        case TableAction.Native:
            return getFrontEndPagination(page_current, page_size, data, indices);
        case TableAction.Custom:
            return getBackEndPagination(data, indices);
        default:
            throw new Error(`Unknown pagination mode: '${page_action}'`);
    }
};
export default memoizeOneFactory(getter);
