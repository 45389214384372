import * as R from 'ramda';
import { ifColumnId, ifRowIndex, ifFilter } from 'dash-table/conditional';
import { TooltipUsage, TooltipSyntax } from 'dash-table/tooltips/props';
import { memoizeOne } from 'core/memoizer';
// 2^32-1 the largest value setTimout can take safely
export const MAX_32BITS = 2147483647;
function getSelectedTooltip(currentTooltip, tooltip_data, tooltip_header, tooltip_conditional, tooltip_static, virtualized) {
    if (!currentTooltip) {
        return undefined;
    }
    const { header, id, row } = currentTooltip;
    if (id === undefined || row === undefined) {
        return undefined;
    }
    const conditionalTooltips = header
        ? undefined
        : R.findLast(tt => {
            return (!tt.if ||
                (ifColumnId(tt.if, id) &&
                    ifRowIndex(tt.if, row) &&
                    ifFilter(tt.if, virtualized.data[row - virtualized.offset.rows])));
        }, tooltip_conditional);
    if (conditionalTooltips) {
        return conditionalTooltips;
    }
    let tooltip;
    if (header) {
        const headerTooltip = tooltip_header?.[id];
        tooltip = Array.isArray(headerTooltip)
            ? headerTooltip?.[row]
            : headerTooltip;
    }
    else {
        tooltip = tooltip_data?.[row]?.[id];
    }
    if (tooltip) {
        return tooltip;
    }
    const staticTooltip = tooltip_static?.[id];
    const staticUseWith = staticTooltip && typeof staticTooltip !== 'string'
        ? staticTooltip.use_with
        : TooltipUsage.Both;
    const staticApplicable = staticUseWith === TooltipUsage.Both ||
        (staticUseWith === TooltipUsage.Header) === header;
    const resolvedStaticTooltip = staticApplicable ? staticTooltip : undefined;
    return resolvedStaticTooltip;
}
function convertDelay(delay) {
    return typeof delay === 'number' ? delay : 0;
}
function convertDuration(duration) {
    return typeof duration === 'number' ? duration : MAX_32BITS;
}
function getDelay(delay, defaultTo) {
    return typeof delay === 'number' || delay === null
        ? convertDelay(delay)
        : defaultTo;
}
function getDuration(duration, defaultTo) {
    return typeof duration === 'number' || duration === null
        ? convertDuration(duration)
        : defaultTo;
}
export default memoizeOne((currentTooltip, tooltip_data, tooltip_header, tooltip_conditional, tooltip_static, virtualized, defaultDelay, defaultDuration) => {
    const selectedTooltip = getSelectedTooltip(currentTooltip, tooltip_data, tooltip_header, tooltip_conditional, tooltip_static, virtualized);
    let delay = convertDelay(defaultDelay);
    let duration = convertDuration(defaultDuration);
    let type = TooltipSyntax.Text;
    let value;
    if (selectedTooltip) {
        if (typeof selectedTooltip === 'string') {
            value = selectedTooltip;
        }
        else {
            delay = getDelay(selectedTooltip.delay, delay);
            duration = getDuration(selectedTooltip.duration, duration);
            type = selectedTooltip.type || TooltipSyntax.Text;
            value = selectedTooltip.value;
        }
    }
    return { delay, duration, type, value };
});
