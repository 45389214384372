import * as R from 'ramda';
import { memoizeOne } from 'core/memoizer';
import memoizerCache from 'core/cache/memoizer';
import { QuerySyntaxTree } from 'dash-table/syntax-tree';
import { ifColumnId } from 'dash-table/conditional';
const mapData = R.addIndex(R.map);
export default () => new Dropdowns().get;
class Dropdowns {
    /**
     * Return the dropdown for each cell in the table.
     */
    get = memoizeOne((columns, data, indices, conditionalDropdowns, staticDropdowns, dataDropdowns) => mapData((datum, rowIndex) => R.map(column => {
        const realIndex = indices[rowIndex];
        const appliedStaticDropdown = (dataDropdowns &&
            dataDropdowns.length > realIndex &&
            dataDropdowns[realIndex] &&
            dataDropdowns[realIndex][column.id]) ||
            staticDropdowns[column.id];
        return this.dropdown.get(column.id, rowIndex)(appliedStaticDropdown, conditionalDropdowns, column, datum);
    }, columns), data));
    /**
     * Returns the highest priority dropdown from the
     * applicable dropdowns.
     */
    dropdown = memoizerCache()((base, conditionals, column, datum) => {
        const conditional = R.findLast(([cd, i]) => ifColumnId(cd.if, column.id) &&
            (R.isNil(cd.if) ||
                R.isNil(cd.if.filter_query) ||
                this.evaluation.get(column.id, i)(this.ast.get(column.id, i)(cd.if.filter_query), datum)), R.addIndex(R.map)((cd, i) => [cd, i], conditionals));
        return (conditional && conditional[0]) || base || undefined;
    });
    /**
     * Get the query's AST.
     */
    ast = memoizerCache()((query) => new QuerySyntaxTree(query));
    /**
     * Evaluate if the query matches the cell's data.
     */
    evaluation = memoizerCache()((ast, datum) => ast.evaluate(datum));
}
