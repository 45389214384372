import cssProperties from './cssProperties';
export const toCamelCase = (fragments) => fragments
    .map((f, i) => (i ? f.charAt(0).toUpperCase() + f.substring(1) : f))
    .join('');
const toKebabCase = (fragments) => fragments.join('-');
const toSnakeCase = (fragments) => fragments.join('_');
const camels = [];
const entries = [];
cssProperties.forEach(prop => {
    const camel = toCamelCase(prop);
    camels.push(camel);
    entries.push([camel, camel]);
    entries.push([toKebabCase(prop), camel]);
    entries.push([toSnakeCase(prop), camel]);
});
export default new Map(entries);
export const KnownCssProperties = camels;
