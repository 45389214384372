import * as R from 'ramda';
import React from 'react';
import { Presentation } from 'dash-table/components/Table/props';
import CellInput from 'dash-table/components/CellInput';
import derivedCellEventHandlerProps, { Handler } from 'dash-table/derived/cell/eventHandlerProps';
import CellLabel from 'dash-table/components/CellLabel';
import CellDropdown from 'dash-table/components/CellDropdown';
import { memoizeOne } from 'core/memoizer';
import getFormatter from 'dash-table/type/formatter';
import { shallowClone } from 'core/math/matrixZipMap';
import CellMarkdown from 'dash-table/components/CellMarkdown';
const mapData = R.addIndex(R.map);
const mapRow = R.addIndex(R.map);
var CellType;
(function (CellType) {
    CellType[CellType["Dropdown"] = 0] = "Dropdown";
    CellType[CellType["DropdownLabel"] = 1] = "DropdownLabel";
    CellType[CellType["Input"] = 2] = "Input";
    CellType[CellType["Label"] = 3] = "Label";
    CellType[CellType["Markdown"] = 4] = "Markdown";
})(CellType || (CellType = {}));
function getCellType(active, editable, dropdown, presentation, is_loading) {
    switch (presentation) {
        case Presentation.Input:
            return !active || !editable || is_loading
                ? CellType.Label
                : CellType.Input;
        case Presentation.Dropdown:
            return !dropdown || !editable
                ? CellType.DropdownLabel
                : CellType.Dropdown;
        case Presentation.Markdown:
            return CellType.Markdown;
        default:
            return !active || !editable || is_loading
                ? CellType.Label
                : CellType.Input;
    }
}
export default (propsFn) => new Contents(propsFn);
class Contents {
    handlers;
    cell_selectable;
    constructor(propsFn, handlers = derivedCellEventHandlerProps(propsFn)) {
        this.handlers = handlers;
        this.cell_selectable = propsFn().cell_selectable;
    }
    partialGet = memoizeOne((columns, data, _offset, isFocused, dropdowns, data_loading, markdown) => {
        const formatters = R.map(getFormatter, columns);
        return mapData((datum, rowIndex) => mapRow((column, columnIndex) => this.getContent(false, false, isFocused, column, dropdowns && dropdowns[rowIndex][columnIndex], columnIndex, rowIndex, datum, formatters, data_loading, markdown), columns), data);
    });
    get = memoizeOne((contents, activeCell, applyFocus, columns, data, offset, isFocused, dropdowns, data_loading, markdown) => {
        if (!activeCell) {
            return contents;
        }
        const { row: iActive, column: jActive } = activeCell;
        const i = iActive - offset.rows;
        const j = jActive - offset.columns;
        if (i < 0 || j < 0 || data.length <= i || columns.length <= j) {
            return contents;
        }
        const formatters = R.map(getFormatter, columns);
        contents = shallowClone(contents);
        contents[i][j] = this.getContent(true, applyFocus || false, isFocused, columns[j], dropdowns && dropdowns[i][j], jActive, iActive, data[i], formatters, data_loading, markdown);
        return contents;
    });
    getContent(active, applyFocus, isFocused, column, dropdown, columnIndex, rowIndex, datum, formatters, data_loading, markdown) {
        const className = [
            ...(active ? ['input-active'] : []),
            isFocused ? 'focused' : 'unfocused',
            ...(this.cell_selectable ? ['selectable'] : []),
            'dash-cell-value'
        ].join(' ');
        const cellType = getCellType(active, column.editable, dropdown && dropdown.options, column.presentation, data_loading);
        switch (cellType) {
            case CellType.Dropdown:
                return (React.createElement(CellDropdown, { key: `column-${columnIndex}`, active: active, applyFocus: applyFocus, clearable: dropdown && dropdown.clearable, dropdown: dropdown && dropdown.options, onChange: this.handlers(Handler.Change, rowIndex, columnIndex), value: datum[column.id], disabled: data_loading }));
            case CellType.Input:
                return (React.createElement(CellInput, { key: `column-${columnIndex}`, active: active, applyFocus: applyFocus, className: className, focused: isFocused, onChange: this.handlers(Handler.Change, rowIndex, columnIndex), onMouseUp: this.handlers(Handler.MouseUp, rowIndex, columnIndex), onPaste: this.handlers(Handler.Paste, rowIndex, columnIndex), type: column.type, value: datum[column.id] }));
            case CellType.Markdown:
                return (React.createElement(CellMarkdown, { key: `column-${columnIndex}`, active: active, applyFocus: applyFocus, className: className, markdown: markdown, value: datum[column.id] }));
            case CellType.DropdownLabel:
            case CellType.Label:
            default:
                const resolvedValue = cellType === CellType.DropdownLabel
                    ? this.resolveDropdownLabel(dropdown, datum[column.id])
                    : formatters[columnIndex](datum[column.id]);
                return (React.createElement(CellLabel, { active: active, applyFocus: applyFocus, className: className, key: `column-${columnIndex}`, value: resolvedValue }));
        }
    }
    resolveDropdownLabel(dropdown, value) {
        const dropdownValue = dropdown &&
            dropdown.options &&
            dropdown.options.find(option => option.value === value);
        return dropdownValue ? dropdownValue.label : value;
    }
}
