export default class DOM {
    static getFirstParentOfType(element, type) {
        type = type.toUpperCase();
        let current = element;
        while (current) {
            if (current.tagName.toUpperCase() === type) {
                return current;
            }
            if (current.parentElement !== null) {
                current = current.parentElement;
            }
            else {
                return;
            }
        }
    }
    static getParentById(element, id) {
        let current = element;
        while (current) {
            if (current.id === id) {
                return current;
            }
            if (current.parentElement !== null) {
                current = current.parentElement;
            }
            else {
                return;
            }
        }
    }
}
