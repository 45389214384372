export function shallowClone(m) {
    const _m_ = m.length;
    const res = new Array(_m_);
    for (let i = 0; i < _m_; ++i) {
        res[i] = m[i].slice(0);
    }
    return res;
}
export function traverse2(a1, a2, fn) {
    const _a1_ = a1.length;
    const _a2_ = a2.length;
    for (let i1 = 0; i1 < _a1_; ++i1) {
        for (let i2 = 0; i2 < _a2_; ++i2) {
            fn(a1[i1], a2[i2], i1, i2);
        }
    }
}
export function traverseMap2(a1, a2, fn) {
    const _a1_ = a1.length;
    const _a2_ = a2.length;
    const res = new Array(_a1_);
    for (let i1 = 0; i1 < _a1_; ++i1) {
        const row = new Array(_a2_);
        for (let i2 = 0; i2 < _a2_; ++i2) {
            row[i2] = fn(a1[i1], a2[i2], i1, i2);
        }
        res[i1] = row;
    }
    return res;
}
export function matrixMap(m1, fn) {
    const _m1_ = m1.length;
    const res = new Array(_m1_);
    for (let i = 0; i < _m1_; ++i) {
        const _row_ = m1[i].length;
        const row = new Array(_row_);
        for (let j = 0; j < _row_; ++j) {
            row[j] = fn(m1[i][j], i, j);
        }
        res[i] = row;
    }
    return res;
}
export function matrixMap2(m1, m2, fn) {
    const _m1_ = m1.length;
    const res = new Array(_m1_);
    for (let i = 0; i < _m1_; ++i) {
        const _row_ = m1[i].length;
        const row = new Array(_row_);
        for (let j = 0; j < _row_; ++j) {
            row[j] = fn(m1[i][j], m2 ? m2[i][j] : undefined, i, j);
        }
        res[i] = row;
    }
    return res;
}
export function matrixMap3(m1, m2, m3, fn) {
    const _m1_ = m1.length;
    const res = new Array(_m1_);
    for (let i = 0; i < _m1_; ++i) {
        const _row_ = m1[i].length;
        const row = new Array(_row_);
        for (let j = 0; j < _row_; ++j) {
            row[j] = fn(m1[i][j], m2 ? m2[i][j] : undefined, m3 ? m3[i][j] : undefined, i, j);
        }
        res[i] = row;
    }
    return res;
}
export function matrixMap4(m1, m2, m3, m4, fn) {
    const _m1_ = m1.length;
    const res = new Array(_m1_);
    for (let i = 0; i < _m1_; ++i) {
        const _row_ = m1[i].length;
        const row = new Array(_row_);
        for (let j = 0; j < _row_; ++j) {
            row[j] = fn(m1[i][j], m2 ? m2[i][j] : undefined, m3 ? m3[i][j] : undefined, m4 ? m4[i][j] : undefined, i, j);
        }
        res[i] = row;
    }
    return res;
}
export function matrixMapN(fn, m1, ...matrices) {
    const _m1_ = m1.length;
    const res = new Array(_m1_);
    for (let i = 0; i < _m1_; ++i) {
        const _row_ = m1[i].length;
        const row = new Array(_row_);
        for (let j = 0; j < _row_; ++j) {
            row[j] = fn(m1[i][j], i, j, ...matrices.map(m => (m ? m[i][j] : undefined)));
        }
        res[i] = row;
    }
    return res;
}
