import * as R from 'ramda';
import { memoizeOneFactory } from 'core/memoizer';
import converter from './py2jsCssProperties';
import { ifColumnId, ifColumnType, ifEditable, ifColumnStateActive, ifColumnStateSelected, ifRowIndex, ifHeaderIndex } from 'dash-table/conditional';
import { QuerySyntaxTree } from 'dash-table/syntax-tree';
import { BORDER_PROPERTIES_AND_FRAGMENTS } from '../edges/type';
import { matchesDataCell, matchesDataOpCell, matchesFilterCell, getFilterOpStyles, matchesHeaderCell, getHeaderOpStyles } from 'dash-table/conditional';
function convertElement(style) {
    let ast;
    return {
        checksColumn: () => !R.isNil(style.if) &&
            (!R.isNil(style.if.column_id) ||
                !R.isNil(style.if.column_type) ||
                !R.isNil(style.if.column_editable)),
        checksFilter: () => !R.isNil(style.if) && !R.isNil(style.if.filter_query),
        checksDataRow: () => !R.isNil(style.if) && !R.isNil(style.if.row_index),
        checksHeaderRow: () => !R.isNil(style.if) && !R.isNil(style.if.header_index),
        checksState: () => !R.isNil(style.if?.state),
        checksStateActive: () => style.if?.state === 'active',
        checksStateSelected: () => style.if?.state === 'selected',
        matchesActive: (active) => ifColumnStateActive(style.if, active),
        matchesColumn: (column) => !style.if ||
            (!R.isNil(column) &&
                ifColumnId(style.if, column && column.id) &&
                ifColumnType(style.if, column && column.type) &&
                ifEditable(style.if, column && column.editable)),
        matchesFilter: (datum) => !style.if ||
            style.if.filter_query === undefined ||
            (ast = ast || new QuerySyntaxTree(style.if.filter_query)).evaluate(datum),
        matchesDataRow: (index) => ifRowIndex(style.if, index),
        matchesHeaderRow: (index) => ifHeaderIndex(style.if, index),
        matchesSelected: (selected) => ifColumnStateSelected(style.if, selected),
        style: convertStyle(style)
    };
}
function convertStyle(style) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return R.reduce((res, [key, value]) => {
        if (converter.has(key)) {
            res[converter.get(key)] = value;
        }
        return res;
    }, {}, R.toPairs(style));
}
export const derivedRelevantCellStyles = memoizeOneFactory((cell, dataCell, cells, dataCells) => R.unnest([
    cell ? [convertElement(cell)] : [],
    R.map(convertElement, cells || []),
    dataCell ? [convertElement(dataCell)] : [],
    R.map(convertElement, dataCells || [])
]));
export const derivedRelevantFilterStyles = memoizeOneFactory((cell, filter, cells, filters) => R.unnest([
    cell ? [convertElement(cell)] : [],
    R.map(convertElement, cells || []),
    filter ? [convertElement(filter)] : [],
    R.map(convertElement, filters || [])
]));
export const derivedRelevantHeaderStyles = memoizeOneFactory((cell, header, cells, headers) => R.unnest([
    cell ? [convertElement(cell)] : [],
    R.map(convertElement, cells || []),
    header ? [convertElement(header)] : [],
    R.map(convertElement, headers || [])
]));
export const derivedTableStyle = memoizeOneFactory((defaultTable, table) => [
    convertStyle(defaultTable),
    convertStyle(table)
]);
export function resolveStyle(styles) {
    const res = {};
    for (let i = 0; i < styles.length; ++i) {
        Object.assign(res, styles[i].style);
    }
    return R.omit(BORDER_PROPERTIES_AND_FRAGMENTS, res);
}
export const getDataCellStyle = (datum, i, column, active, selected) => (styles) => resolveStyle(matchesDataCell(datum, i, column, active, selected)(styles));
export const getDataOpCellStyle = (datum, i) => (styles) => resolveStyle(matchesDataOpCell(datum, i)(styles));
export const getFilterCellStyle = (column) => (styles) => resolveStyle(matchesFilterCell(column)(styles));
export const getFilterOpCellStyle = () => (styles) => resolveStyle(getFilterOpStyles(styles));
export const getHeaderCellStyle = (i, column) => (styles) => resolveStyle(matchesHeaderCell(i, column)(styles));
export const getHeaderOpCellStyle = (i) => (styles) => resolveStyle(getHeaderOpStyles(i)(styles));
