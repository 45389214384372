export var TooltipSyntax;
(function (TooltipSyntax) {
    TooltipSyntax["Text"] = "text";
    TooltipSyntax["Markdown"] = "markdown";
})(TooltipSyntax || (TooltipSyntax = {}));
export var TooltipUsage;
(function (TooltipUsage) {
    TooltipUsage["Both"] = "both";
    TooltipUsage["Data"] = "data";
    TooltipUsage["Header"] = "header";
})(TooltipUsage || (TooltipUsage = {}));
