import * as R from 'ramda';
import { ColumnType } from 'dash-table/components/Table/props';
import { QuerySyntaxTree } from 'dash-table/syntax-tree';
function ifAstFilter(ast, datum) {
    return ast.isValid && ast.evaluate(datum);
}
export function ifColumnStateActive(condition, active) {
    return condition?.state !== 'active' || active;
}
export function ifColumnStateSelected(condition, selected) {
    return condition?.state !== 'selected' || selected;
}
export function ifColumnId(condition, columnId) {
    if (!condition || condition.column_id === undefined) {
        return true;
    }
    return Array.isArray(condition.column_id)
        ? R.includes(columnId, condition.column_id)
        : condition.column_id === columnId;
}
export function ifColumnType(condition, columnType) {
    return (!condition ||
        condition.column_type === undefined ||
        condition.column_type === (columnType || ColumnType.Any));
}
export function ifRowIndex(condition, rowIndex) {
    if (!condition || condition.row_index === undefined) {
        return true;
    }
    const rowCondition = condition.row_index;
    return typeof rowCondition === 'string'
        ? rowIndex % 2 === (rowCondition === 'odd' ? 1 : 0)
        : Array.isArray(rowCondition)
            ? R.includes(rowIndex, rowCondition)
            : rowIndex === rowCondition;
}
export function ifHeaderIndex(condition, headerIndex) {
    if (!condition || condition.header_index === undefined) {
        return true;
    }
    const headerCondition = condition.header_index;
    return typeof headerCondition === 'string'
        ? headerIndex % 2 === (headerCondition === 'odd' ? 1 : 0)
        : Array.isArray(headerCondition)
            ? R.includes(headerIndex, headerCondition)
            : headerIndex === headerCondition;
}
export function ifFilter(condition, datum) {
    return (!condition ||
        condition.filter_query === undefined ||
        ifAstFilter(new QuerySyntaxTree(condition.filter_query), datum));
}
export function ifEditable(condition, isEditable) {
    if (!condition || condition.column_editable === undefined) {
        return true;
    }
    return isEditable === condition.column_editable;
}
export const matchesDataCell = (datum, i, column, active, selected) => R.filter(style => !style.checksHeaderRow() &&
    style.matchesActive(active) &&
    style.matchesSelected(selected) &&
    style.matchesDataRow(i) &&
    style.matchesColumn(column) &&
    style.matchesFilter(datum));
export const matchesFilterCell = (column) => R.filter(style => !style.checksState() &&
    !style.checksDataRow() &&
    !style.checksHeaderRow() &&
    style.matchesColumn(column));
export const matchesHeaderCell = (i, column) => R.filter(style => !style.checksState() &&
    !style.checksDataRow() &&
    style.matchesHeaderRow(i) &&
    style.matchesColumn(column));
export const matchesDataOpCell = (datum, i) => R.filter(style => !style.checksState() &&
    !style.checksColumn() &&
    !style.checksHeaderRow() &&
    style.matchesDataRow(i) &&
    style.matchesFilter(datum));
export const getFilterOpStyles = R.filter(style => !style.checksState() &&
    !style.checksDataRow() &&
    !style.checksHeaderRow() &&
    !style.checksColumn());
export const getHeaderOpStyles = (i) => R.filter(style => !style.checksDataRow() &&
    !style.checksState() &&
    !style.checksColumn() &&
    style.matchesHeaderRow(i));
