import { memoizeOneFactory } from 'core/memoizer';
const getter = (indices, selectedRows) => {
    const map = new Map();
    indices.forEach((virtualIndex, index) => {
        map.set(virtualIndex, index);
    });
    const mappedSelectedRows = [];
    selectedRows.forEach(rowIndex => {
        const virtualIndex = map.get(rowIndex);
        if (virtualIndex !== undefined) {
            mappedSelectedRows.push(virtualIndex);
        }
    });
    return mappedSelectedRows;
};
export default memoizeOneFactory(getter);
