import Logger from 'core/Logger';
import { LexemeType } from 'core/syntax-tree/lexicon';
var LogicalOperator;
(function (LogicalOperator) {
    LogicalOperator["And"] = "&&";
    LogicalOperator["Or"] = "||";
})(LogicalOperator || (LogicalOperator = {}));
export const and = {
    evaluate: (target, tree) => {
        Logger.trace('evaluate -> &&', target, tree);
        const t = tree;
        const lv = t.left.lexeme.evaluate(target, t.left);
        const rv = t.right.lexeme.evaluate(target, t.right);
        return lv && rv;
    },
    type: LexemeType.LogicalOperator,
    priority: 2,
    regexp: /^(and\s|&&)/i,
    subType: LogicalOperator.And,
    syntaxer: (lexs, pivot, pivotIndex) => {
        return Object.assign({
            left: lexs.slice(0, pivotIndex),
            right: lexs.slice(pivotIndex + 1)
        }, pivot);
    }
};
export const or = {
    evaluate: (target, tree) => {
        Logger.trace('evaluate -> ||', target, tree);
        const t = tree;
        return (t.left.lexeme.evaluate(target, t.left) ||
            t.right.lexeme.evaluate(target, t.right));
    },
    type: LexemeType.LogicalOperator,
    subType: LogicalOperator.Or,
    priority: 3,
    regexp: /^(or\s|\|\|)/i,
    syntaxer: (lexs, pivot, pivotIndex) => {
        return Object.assign({
            left: lexs.slice(0, pivotIndex),
            right: lexs.slice(pivotIndex + 1)
        }, pivot);
    }
};
